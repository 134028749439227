var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c("v-card-text", { staticClass: "py-0 px-2" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.member.response) +
                    " - " +
                    _vm._s(_vm.member.description) +
                    " "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c("v-card-text", { staticClass: "py-0 px-2" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.formatTicketDateTime(_vm.member.responded)) +
                    " "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }