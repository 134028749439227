<template>
  <v-container>
    <v-row dense>
      <v-col>
        <v-card-text class="py-0 px-2">
          {{ member.response }} - {{ member.description }}
        </v-card-text>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-card-text class="py-0 px-2">
          {{ formatTicketDateTime(member.responded) }}
        </v-card-text>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { formatTicketDateTime } from "@/store/utils/utils.js";
export default {
  props: {
    member: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    formatTicketDateTime(dateString) {
      return formatTicketDateTime(dateString);
    },
  },
};
</script>
<style></style>
